<template>
    <v-container fluid class="pt-0">
        <v-row>
            <v-col cols="12">
                <v-row justify="center" class="py-3">
                    <vue-typer 
                        :text="$store.getters['companyProfile/company'] != null ? $store.getters['companyProfile/company'].company_name : '!'"
                        :erase-delay="parseInt(150)"
                        :type-delay="parseInt(300)"
                        erase-style="backspace" 
                    >
                    </vue-typer>
                </v-row>

                <v-divider class="mt-2 mb-4"></v-divider>

                <v-row justify="center">
                    <v-col cols="12" md="10">
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-card to="/patient" elevation="1" color="teal accent-3">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="80">mdi-human-wheelchair</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Patient Entry</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/doctor" elevation="1" color="cyan accent-4">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="80">mdi-account-tie</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Doctor Module</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/account/test-receipt" elevation="1" color="teal accent-4">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="80">mdi-currency-usd</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Accounts Module</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/purchase" elevation="1" color="cyan lighten-2">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="80">mdi-cart</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Store Module</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/employee" elevation="1" color="teal lighten-2">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="80">mdi-account-supervisor</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Employee Module</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/lab/test" elevation="1" color="indigo lighten-3">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="80">mdi-flask</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Lab Module</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/business-monitor" elevation="1" color="teal lighten-3">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="80">mdi-chart-bar</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Business Monitor</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card @click="logout" elevation="1" color="blue lighten-3">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="80">mdi-login-variant</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Logout</div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { VueTyper } from 'vue-typer';

export default {
    name: 'Dashboard',
    components: {
        VueTyper,
    },
    methods: {
        logout() {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login')
        }
    }
}
</script>

<style lang="scss" scoped>
.module-card-text {
    padding: 12px 5px 3px;
    text-align: center;

    .v-icon {
        color: #263238 !important;
    }
}
.module-card-title {
    text-align: center;
    padding-bottom: 12px;
    font-weight: bold;
    letter-spacing: .6px;
    color: #000000;
}
.vue-typer {
    font-size: 40px;
    font-weight: 500;
}
</style>